<template>



  <div>
    
    

    <div id="gmaptraining">

    </div>
    <div>
       <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="mini-spinner"
    />
    <ServerError v-if="ServerError" />
    
   <!-- <Details/> -->
      <!-- <span>{{snareData}};;;;;;;;kkkkkkkk</span> -->
  
    </div>
   
    
   
  </div>
</template>
<script
    src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyBTkuPaji75FkC_WBegL4cupA9_HJWNAuE&libraries=visualization,drawing">
</script>
<script>
import axios from "axios";
// import Details from './dataPage';
export default {

  props:["lat" ,"lon"],
  // components:
  // {
  // Details
  // },
  data() {
    return {

      defaultLAt:0,
      defaultLON:0,
      icons: "",
      totalLength: 0,
      page: 1,
      appLoading: false,
      ServerError: false,
      user: [],
      records: [],
      gmap: null,
      pagelength: 0,
      allData:[],
    
      //map data
      latt: null,
      lonn: null,
    };
  },
//   watch: {
//     snareData() {
//       this.snareList();
//     },
//   }, 

  // beforeMount()
  // {
  //    this.getMap();
  // },

  mounted() {
    // this.getData();
    this.getMap();
    // this.snareList();
   
  },
  methods: {

   snareList()
   {
     console.log("im working")
     console.log("snareLength",this.snareData.length)
     

      for (let i = 0; i < this.snareData.length; i++) {
        console.log("this.snareData",this.snareData)
              this.latt = this.snareData[i].snareLocation[1];
              this.lonn = this.snareData[i].snareLocation[0];
               let  l = i+1;
              this.icons = {
              
                 url: require("../../assets/images/markers/greenMarker.png"),
                size: new google.maps.Size(100, 60),
                scaledSize: new google.maps.Size(20, 30),
                anchor: new google.maps.Point(0, 50),
    
              };
              this.marker = new google.maps.Marker({
                position: { lat: this.latt, lng: this.lonn },
                map: this.gmap,
                icon: this.icons,
                
              });
              // const infowindow = new google.maps.InfoWindow({
              //   content: String(
              //    "Snare" + "<h3>" + l  +  "</h3>" ,
                 
                   
                   
              //   ),
              //   maxWidth: 500,
              //   pixelOffset: new google.maps.Size( -53, 0 )

              // });
              this.marker.addListener("click", (eve) => {
                infowindow.open(this.map3, this.marker);
                infowindow.setPosition(eve.latLng);
              });
            }


   },






    ChangeFormateDate(oldDate) {
      if (oldDate) {
        var newdateFormat = oldDate.split("T");
        var splitDate = newdateFormat[0].split("-");
        if (splitDate.count == 0) {
          return null;
        }
        var year = splitDate[0];
        var month = splitDate[1];
        var day = splitDate[2];
        return month + "/" + day + "/" + year;
      }
    },
    
    getMap() {
      this.gmap = new google.maps.Map(document.getElementById("gmaptraining"), {
        center: { lat:this.lat, lng:  this.lon },
       
        // center: { lat: this.defaultLAt, lng: this.defaultLON },
        // center: { lat: this.defaultLON, lng: this.defaultLAt },

        mapTypeId: 'satellite',
        zoom: 8,
      });
    


     this.icons = {
              
                 url: require("../../assets/images/markers/greenMarker.png"),
                size: new google.maps.Size(100, 60),
                scaledSize: new google.maps.Size(20, 30),
                anchor: new google.maps.Point(0, 50),
    
              };
     {



  
                
     
     this.marker = new google.maps.Marker({
                position: { lat: this.lat, lng: this.lon },
                map: this.gmap,
                icon: this.icons,
                
              });
     }
    },

     getData() {
      this.appLoading = true;
      axios({
        url: "/admin/view/report/details",
        method: "post",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          page: this.page,
          limit: 25,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.allData = response.data.data;
            this.participents = response.data.data.participents;
            this.snareReports = response.data.reports;
           this.defaultLAt = response.data.data.startGps[1];
           this.defaultLON = response.data.data.startGps[0];

             this.getMap();
              for (let i = 0; i < response.data.reports.length; i++) {
              this.latt = response.data.reports[i].snareLocation[1];
              this.lonn = response.data.reports[i].snareLocation[0];
               let  l = i+1;
              this.icons = {
                // url: require("../../assets/images/whale.svg"),
                size: new google.maps.Size(46, 50),
                scaledSize: new google.maps.Size(32, 35),
                anchor: new google.maps.Point(0, 50),
                
              };
              this.marker = new google.maps.Marker({
                position: { lat: this.latt, lng: this.lonn },
                map: this.gmap,
                icon: this.icons,
                
              });
              const infowindow = new google.maps.InfoWindow({
                content: String(
                 "Snare" + "<h3>" + l  +  "</h3>" ,
                 
                   
                   
                ),
                maxWidth: 500,
                pixelOffset: new google.maps.Size( -53, 0 )

              });
              this.marker.addListener("click", (eve) => {
                infowindow.open(this.map3, this.marker);
                infowindow.setPosition(eve.latLng);
              });
            }
             for (let i = 0; i < response.data.data.gps.length; i++) {
              this.latt = response.data.data.gps[i].location[1];
              this.lonn = response.data.data.gps[i].location[0];
              this.icons = {
                // url: require("../../assets/images/black.svg"),
                // url: require("../../assets/images/blue.png"),
                url: require("../../assets/images/images.png"),

                size: new google.maps.Size(15, 20),
                scaledSize: new google.maps.Size(5, 10),
                anchor: new google.maps.Point(0, 50),
                
              };
              this.marker = new google.maps.Marker({
                position: { lat: this.latt, lng: this.lonn },
                map: this.gmap,
                // icon: this.icons,
                icon: this.icons 
                
              });

              // const infowindow = new google.maps.InfoWindow({ 
              //   content: String(
              //    "Report No" + "<h3>" + i+1 +  "</h3>" 
                   
                   
              //   ),
              //   maxWidth: 500,
              // });
              // this.marker.addListener("click", (eve) => {
              //   infowindow.open(this.map3, this.marker);
              //   infowindow.setPosition(eve.latLng);
              // });
            }
              {
              this.latt = response.data.data.startGps[1];
              this.lonn = response.data.data.startGps[0];
              this.icons = {
                url: require("../../assets/images/green.png"),
                size: new google.maps.Size(100, 60),
                scaledSize: new google.maps.Size(20, 30),
                anchor: new google.maps.Point(0, 50),
                
              };
              this.marker = new google.maps.Marker({
                position: { lat: this.latt, lng: this.lonn },
                map: this.gmap,
                // icon: this.icons,
                icon: this.icons 
                
              });

              const infowindow = new google.maps.InfoWindow({ 
                content: String(
                 "Start Point"  
                ),
                maxWidth: 500,
                pixelOffset: new google.maps.Size( -40, 0 )
              });
              this.marker.addListener("click", (eve) => {
                infowindow.open(this.map3, this.marker);
                infowindow.setPosition(eve.latLng);
              });
            };
              {
              this.latt = response.data.data.endGps[1];
              this.lonn = response.data.data.endGps[0];
              this.icons = {
                url: require("../../assets/images/yellow.png"),
                size: new google.maps.Size(100, 60),
                scaledSize: new google.maps.Size(20, 30),
                anchor: new google.maps.Point(0, 50),
                
              };
              this.marker = new google.maps.Marker({
                position: { lat: this.latt, lng: this.lonn },
                map: this.gmap,
                // icon: this.icons,
                icon: this.icons 
                
              });

              const infowindow = new google.maps.InfoWindow({ 
                content: String(
                 "End Point"  
                ),
                maxWidth: 500,
                 pixelOffset: new google.maps.Size( -40, 0 )
              });
              this.marker.addListener("click", (eve) => {
                infowindow.open(this.map3, this.marker);
                infowindow.setPosition(eve.latLng);
              });
            }
           
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    
  },
};
</script>

<style scoped>
#gmaptraining {
  height: 400px;
  width: 100%;
  margin: 0px;
  padding: 0px;
}
</style>
